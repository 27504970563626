import React from "react";
import { Switch, Route } from "react-router";
import Login from "./../views/Login";
import ForgotPassword from "./../views/ForgotPassword";
import ResetPassword from "./../views/ResetPassword";

class Auth extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset_password" component={ResetPassword} />
        </Switch>
      </div>
    );
  }
}

export default Auth;
