import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from "reactstrap";
import _ from "lodash";

import API from "../../libs/api";
class LoginPage extends Component {
  state = {
    email: "",
    password: "",
    isError: false,
    isLoading: false
  };

  login = async () => {
    const { email, password } = this.state;
    this.setState({
      isLoading: true,
      isError: false
    });
    try {
      const response = await API.post(
        "/signin",
        {
          email,
          password
        },
        {},
        false
      );
      this.setState({
        isLoading: false
      });
      const { data, headers } = response;
      if (data.success) {
        const displayName = _.get(data, "display_name", "");
        const lastSignin = _.get(data, "last_signin_time", "")
        const email = _.get(data, "email", "");
        const session = _.get(headers, "x-plug-authorization", "");
        let splittedSession = session.split(";");
        splittedSession = splittedSession.filter(x => {
          return x !== "";
        });
        const sData = {};
        splittedSession
          .map(x => {
            return x.split("=");
          })
          .forEach(x => {
            sData[x[0]] = x[1];
          });
        if (!!sData.id) {
          sessionStorage.setItem("plugSession", sData.id);
          sessionStorage.setItem("plugExpire", sData.expires);
          sessionStorage.setItem("plugRole", data.role);
          localStorage.setItem(
            "sessionStorage",
            JSON.stringify(sessionStorage)
          );
          localStorage.removeItem("sessionStorage");
          const login = _.get(this.props, "login", () => {});
          login(sData.id, sData.expires, data.role, displayName, lastSignin, email);
        }
        const {
          history: { push }
        } = this.props;
        push("/home");
      } else {
        this.setState({
          isError: true
        });
      }
    } catch {
      this.setState({
        isLoading: false
      });
    }
  };

  componentDidMount() {}

  render() {
    const { email, password, isError, isLoading } = this.state;
    return (
      <div>
        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <div className="bg-primary p-4 text-white text-center position-relative">
              <h4 className="font-20 m-b-5">CMS</h4>
              <p className="text-white-50 mb-4">Sign in to continue.</p>
              <Link
                to="/"
                className="logo logo-admin"
                style={{ borderBottom: "1px solid #AAAAAA" }}
              >
                <img src="assets/images/logo-sm.png" height="45" alt="logo" />
              </Link>
            </div>
            <div className="account-card-content">
              <form className="form-horizontal m-t-30" action="/">
                {isError && (
                  <div style={{ marginBottom: "20px" }}>
                    <div className="alert alert-danger mb-0" role="alert">
                      <strong>Oh !</strong> Incorrect email or password. Please
                      try again.
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="username">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="username"
                    placeholder="Email"
                    value={email}
                    disabled={isLoading}
                    onChange={event => {
                      this.setState({
                        email: event.target.value,
                        isError: false
                      });
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="userpassword"
                    placeholder="Enter password"
                    value={password}
                    disabled={isLoading}
                    onChange={event => {
                      this.setState({
                        password: event.target.value,
                        isError: false
                      });
                    }}
                  />
                </div>

                <div
                  className="form-group row m-t-20"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="col-sm-6">
                    <Link to="forgot-password">
                      <i className="mdi mdi-lock" /> Forgot your password?
                    </Link>
                  </div>
                  <div className="col-sm-6 text-right">
                    <Button
                      className="btn btn-primary w-md waves-effect waves-light"
                      disabled={isLoading}
                      onClick={this.login}
                    >
                      Log In
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {};
};

const mapDispatchtoProps = dispatch => {
  return {
    login: (plugSession, plugExpire, plugRole, displayName, lastSignin,  email) => {
      dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire, plugRole, displayName, lastSignin, email }
      });
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(LoginPage);
