import React from "react";
import { Route, Switch } from "react-router"; // react-router v4/v5

import { ConnectedRouter } from "connected-react-router";
import LoginDedicated from "./views/Login";
import ForgotPasswordDedicated from "./views/ForgotPassword";
import ResetPasswordDedicated from "./views/ResetPassword";
import NotFoundDedicated from "./views/NotFound";

import Dashboard from "./layouts/Dashboard";
import Auth from "./layouts/Auth";
function App(props) {
  return (
    <ConnectedRouter history={props.history}>
      <Switch>
        <Route path="/" exact component={LoginDedicated} />
        <Route path="/login" exact component={LoginDedicated} />
        <Route path="/forgot-password" exact component={ForgotPasswordDedicated} />
        <Route path="/reset_password" exact component={ResetPasswordDedicated} />
        <Route component={Dashboard} />
        <Route component={NotFoundDedicated} />
      </Switch>
    </ConnectedRouter>
  );
}

export default App;
