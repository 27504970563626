import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import API from "../../libs/api";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

class topbar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);

    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      isOpenDialogLogout: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  toggle1() {
    this.setState(prevState => ({
      dropdownOpen1: !prevState.dropdownOpen1
    }));
  }
  toggleprofile() {
    this.setState(prevState => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile
    }));
  }
  togglebadge() {
    this.setState(prevState => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge
    }));
  }

  togglescreen(e) {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  openLogoutDialog = () => {
    this.setState({
      isOpenDialogLogout: true
    });
  };

  goToLogin = () => {
    const {
      history: { push }
    } = this.props;
    API.post("/signout");
    sessionStorage.removeItem("plugSession");
    sessionStorage.removeItem("plugExpire");
    sessionStorage.removeItem("plugRole");
    push("/");
  };

  render() {
    const { email, lastSignin } = this.props;
    const { isOpenDialogLogout } = this.state;
    return (
      <div className="topbar">
        {isOpenDialogLogout && (
          <SweetAlert
            showCancel
            confirmBtnText="Logout"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="link"
            cancelBtnText="Cancel"
            title="Are you sure you want to logout now?"
            onConfirm={this.goToLogin}
            onCancel={() => {
              this.setState({
                isOpenDialogLogout: false
              });
            }}
          />
        )}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span style={{ color: "white" }}>TMN</span>
            <i className="gaswhite">
              <span
                style={{
                  opacity: 1,
                  display: "inline-block",
                  color: "white",
                  fontSize: "13px"
                }}
              >
                TMN
              </span>
            </i>
          </Link>
        </div>

        <nav className="navbar-custom">
          <ul className="navbar-right d-flex list-inline float-right mb-0">
            <li
              className="dropdown notification-list"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span>
                Last login :{" "}
                {(lastSignin === "" || new Date(lastSignin).getYear() < 0)
                  ? "-"
                  : new Date(lastSignin).toLocaleDateString("en-EN", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                    })}
              </span>
            </li>
            <li className="dropdown notification-list d-none d-md-block">
              <Link
                onClick={() => this.togglescreen()}
                className="nav-link waves-effect"
                to="#"
                id="btn-fullscreen"
              >
                <i className="mdi mdi-fullscreen noti-icon" />
              </Link>
            </li>

            <li className="dropdown notification-list">
              <div className="dropdown notification-list nav-pro-img">
                <Dropdown
                  isOpen={this.state.dropdownOpenprofile}
                  toggle={this.toggleprofile}
                >
                  <DropdownToggle
                    className="dropdown-toggle testflag nav-link arrow-none waves-effect nav-user"
                    tag="a"
                  >
                    <img
                      src="assets/images/users/user-4.png"
                      alt="user"
                      className="rounded-circle"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>{email}</DropdownItem>
                    <DropdownItem onClick={this.openLogoutDialog}>
                      <i className="mdi mdi-power text-danger" /> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.auth.email,
    lastSignin: state.auth.lastSignin
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(topbar));
