import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store, { history } from "./configureStore";
import API from "./libs/api";
import _ from "lodash";

// transfers sessionStorage from one tab to another
var sessionStorage_transfer = function(event) {
  if (!event) {
    event = window.event;
  } // ie suq
  if (!event.newValue) return; // do nothing if no value to work with
  if (event.key == "getSessionStorage") {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
  } else if (event.key == "sessionStorage") {
    // another tab sent data <- get it
    var data = JSON.parse(event.newValue);
    if (
      !sessionStorage.length ||
      (!!sessionStorage.length &&
        parseInt(sessionStorage.getItem("plugExpire")) <
          parseInt(_.get(data, "plugExpire", 0)))
    ) {
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  }
};

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener("storage", sessionStorage_transfer, false);
} else {
  window.attachEvent("onstorage", sessionStorage_transfer);
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
  localStorage.setItem("getSessionStorage", "foobar");
  localStorage.removeItem("getSessionStorage", "foobar");
}

// delay for syncing
setTimeout(() => {
  API.get("/keep-alive", {}, false)
    .then(response => {
      const { data } = response;
      if (data.success) {
        const displayName = _.get(data, "display_name", "");
        const lastSignin = _.get(data, "last_signin_time", "");
        const email = _.get(data, "email", "");
        const plugRole = _.get(data, "role", "");
        const plugSession = API.getSession();
        const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
        sessionStorage.setItem("plugSession", plugSession);
        sessionStorage.setItem("plugExpire", plugExpire);
        sessionStorage.setItem("plugRole", plugRole);
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        localStorage.removeItem("sessionStorage");
        store.dispatch({
          type: "CUSTOM",
          data: { plugSession, plugExpire, plugRole, displayName, lastSignin, email }
        });
        switch (window.location.pathname) {
          case "/":
          case "/login":
            window.location.replace("/home");
            break;
          default:
            ReactDOM.render(
              <Provider store={store}>
                <App history={history} />
              </Provider>,
              document.getElementById("root")
            );
            break;
        }
      } else {
        ReactDOM.render(
          <Provider store={store}>
            <App history={history} />
          </Provider>,
          document.getElementById("root")
        );
      }
    })
    .catch(error => {
      const responseStatus = _.get(error, "response.status", 0);
      if (responseStatus === 401 || responseStatus === 403) {
        switch (window.location.pathname) {
          case "/home":
          case "/report-daily":
          case "/report-monthly":
          case "/staff":
          case "/create-staff":
          case "/healthcheck":
          case "/maintenance":
            window.location.replace("/");
            break;
          default:
            ReactDOM.render(
              <Provider store={store}>
                <App history={history} />
              </Provider>,
              document.getElementById("root")
            );
            break;
        }
      } else {
        ReactDOM.render(
          <Provider store={store}>
            <App history={history} />
          </Provider>,
          document.getElementById("root")
        );
      }
    });
}, 100);
serviceWorker.unregister();
