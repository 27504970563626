import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import API from "../../libs/api";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  }
};

class BlankPage extends Component {
  state = {
    date: new Date(),
    settlement_it_service_summary: false,
    settlement_marketing_fee: false,
    settlement_sale_operation: false,
    settlement_marketing_sp_fee: false,
    settlement_it_service_detail: false,
    option: "all"
  };

  handleChangeMonth = date => {
    this.setState({
      date
    });
  };

  componentDidMount = () => {
    const { email = "" } = this.props;
    if (email === "tmn@covid.com") {
      this.setState({
        option: "covidtip"
      });
    } else if (email === "tmn@covidasia.com") {
      this.setState({
        option: "covidasia"
      });
    } else if (email === "tmn@cancer.com") {
      this.setState({
        option: "cancer"
      });
    }
  };

  productTypeOptions = product_email => {
    if(product_email === "tmn@covid.com"){
    return(
      <>
        <option value="covidtip">Covid Dhipaya</option>
        <option value="covidbki">Covid BKI</option>
        <option value="covidsmk">Covid SMK</option>
      </>
      )
    } else {
      return(
        <>
          <option value="covidasia">Covid Asia</option>
        </>
      )
    }
  }

  exportData = async () => {
    const {
      date,
      option,
      settlement_it_service_summary,
      settlement_marketing_fee,
      settlement_sale_operation,
      settlement_marketing_sp_fee,
      settlement_it_service_detail
    } = this.state;

    let type = [];
    if (!!settlement_it_service_summary) {
      type.push("settlement_it_service_summary");
    }
    if (!!settlement_it_service_detail && ["covid", "cancer", "endowment"].some(el => option.includes(el))) {
      type.push("settlement_it_service_detail");
    }
    if (!!settlement_marketing_fee && !["covid", "cancer"].some(el => option.includes(el))) {
      type.push("settlement_marketing_fee");
    }
    if (!!settlement_sale_operation && !["covid", "cancer"].some(el => option.includes(el))) {
      type.push("settlement_sale_operation");
    }
    if (!!settlement_marketing_sp_fee && !["covid", "cancer"].some(el => option.includes(el))) {
      type.push("settlement_marketing_sp_fee");
    }
    let path = "monthly";

    if (option === "covidtip") {
      path = "covid_tip_monthly";
    } else if (option === "covidsmk") {
      path = "covid_smk_monthly";
    } else if (option === "covidasia") {
      path = "asia_covid19_monthly";
    } else if (option === "covidbki") {
      path = "covidbki_monthly";
    } else if (option === "cancer") {
      path = "cancer_monthly";
    } else if (option === "endowment") {
      path = "endowment_monthly";
    }

    API.get(
      "/reports/" +
        path +
        "?ym=" +
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "&product=" +
        option +
        "&type=" +
        type.join(",")
    ).then(response => {
      let a = document.createElement("a");
      const url = response.data; // "https://database-s3dataexport-7usxcjuptmck.s3-ap-southeast-1.amazonaws.com/tmp/monthly_report.zip";
      a.href = url;
      const fileName = url.split("/").pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      //FileDownload(response.data, "report-" + new Date().getTime() + ".zip");
    });
  };

  render = () => {
    const { classes, role, email = "" } = this.props;
    const {
      date,
      option,
      settlement_it_service_summary,
      settlement_marketing_fee,
      settlement_sale_operation,
      settlement_marketing_sp_fee,
      settlement_it_service_detail
    } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Report</h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formContainer}>
                <div className={classes.dateContainer}>
                  <h6 style={{ marginTop: "0px" }}>Month:</h6>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3}>
                      <DatePicker
                        dateFormat="MMMM yyyy"
                        className="form-control"
                        selected={date}
                        onChange={this.handleChangeMonth}
                        maxDate={new Date()}
                        id="date_start"
                        showMonthYearPicker
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.columnContainer}>
                  <h6>Product:</h6>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3}>
                        {!(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                          <select
                            value={option}
                            onChange={event => {
                              this.setState({
                                option: event.target.value
                              });
                            }}
                            className="form-control"
                          >
                            <option value="all">All Products</option>
                            <option value="cmi">CMI</option>
                            <option value="vmi">VMI</option>
                            { role !== "bki_fin_opt_business" &&
                              <option value="endowment">Endowment</option>
                            }
                            {/* <option value="ta">TA</option> */}
                          </select>
                        )}
                        {(["tmn@covid.com", "tmn@covidasia.com"]).includes(email) && (
                          <select
                            value={option}
                            onChange={event => {
                              this.setState({
                                option: event.target.value
                              });
                            }}
                            className="form-control"
                          >
                          {
                            this.productTypeOptions(email)
                          }                          
                          </select>
                        )}
                        {(["tmn@cancer.com"]).includes(email) && (
                          <select
                            value={option}
                            onChange={event => {
                              this.setState({
                                option: event.target.value
                              });
                            }}
                            className="form-control"
                          >
                          <option value="cancer">Cancer</option>
                          </select>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.columnContainer}>
                  <h6>Report:</h6>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      {["covid", "cancer", "endowment"].some(el => option.includes(el)) ? (
                        <Grid item xs={12} sm={8} md={6}>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    settlement_it_service_summary &&
                                    settlement_it_service_detail
                                  }
                                  onChange={event => {
                                    this.setState({
                                      settlement_it_service_summary:
                                        event.target.checked,
                                      settlement_it_service_detail:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="All"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_it_service_summary}
                                  onChange={event => {
                                    this.setState({
                                      settlement_it_service_summary:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement It Service summary"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_it_service_detail}
                                  onChange={event => {
                                    this.setState({
                                      settlement_it_service_detail:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement It Service detail"
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={8} md={6}>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    settlement_it_service_summary &&
                                    settlement_marketing_fee &&
                                    settlement_sale_operation &&
                                    settlement_marketing_sp_fee
                                  }
                                  onChange={event => {
                                    this.setState({
                                      settlement_it_service_summary:
                                        event.target.checked,
                                      settlement_marketing_fee:
                                        event.target.checked,
                                      settlement_sale_operation:
                                        event.target.checked,
                                      settlement_marketing_sp_fee:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="All"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_it_service_summary}
                                  onChange={event => {
                                    this.setState({
                                      settlement_it_service_summary:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement It Service summary"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_marketing_fee}
                                  onChange={event => {
                                    this.setState({
                                      settlement_marketing_fee:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement Marketing Fee"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_sale_operation}
                                  onChange={event => {
                                    this.setState({
                                      settlement_sale_operation:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement Sale Operation"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={settlement_marketing_sp_fee}
                                  onChange={event => {
                                    this.setState({
                                      settlement_marketing_sp_fee:
                                        event.target.checked
                                    });
                                  }}
                                  color="primary"
                                />
                              }
                              label="Settlement Marketing Sp Fee"
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={() => {
                      this.setState({
                        date: new Date(),
                        settlement_it_service_summary: false,
                        settlement_marketing_fee: false,
                        settlement_sale_operation: false,
                        settlement_marketing_sp_fee: false,
                        settlement_it_service_detail: false,
                      });
                    }}
                    style={{ marginRight: "9px" }}
                    className="btn btn-light waves-effect"
                  >
                    Clear
                  </Button>
                  <Button color="link" onClick={this.exportData}>
                    Export
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.plugRole,
    email: state.auth.email
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
