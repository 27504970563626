import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import API from "../../libs/api";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formContainerLoading: {
    backgroundColor: "white",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  },
  statusOK: {
    display: "inline-block",
    width: "15px",
    height: "15px",
    backgroundColor: "#02a499",
    borderRadius: "50%",
    marginTop: "3px",
    marginLeft: "15px"
  },
  statusFailed: {
    display: "inline-block",
    width: "15px",
    height: "15px",
    backgroundColor: "#ec4561",
    borderRadius: "50%",
    marginTop: "3px",
    marginLeft: "15px"
  },
  dateContainer: {
    display: "flex"
  }
};

class BlankPage extends Component {
  state = {
    isLoading: true,
    bkiStatus: false,
    trueBalanceStatus: false,
    truePaymentStatus: false
  };

  componentDidMount = async () => {
    try {
      const response = await API.get("/service_health");
      if (response.status === 200) {
        const data = _.get(response, "data", {});
        const bki = _.get(data, "bki-check", {});
        const trueBalance = _.get(data, "true-balance-check", {});
        const truePayment = _.get(data, "true-payment-check", {});
        const bkiStatus = bki.status === true;
        const trueBalanceStatus = trueBalance.status === true;
        const truePaymentStatus = truePayment.status === true;

        this.setState({
          isLoading: false,
          bkiStatus,
          trueBalanceStatus,
          truePaymentStatus
        });
      }
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if (responseStatus === 401) {
        const {
          history: { push }
        } = this.props;
        push("/login");
      }
    }
  };

  render = () => {
    const { classes } = this.props;
    const {
      bkiStatus,
      trueBalanceStatus,
      truePaymentStatus,
      isLoading
    } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Health-check</h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading ? (
                <div className={classes.formContainerLoading}>
                  <CircularProgress
                    color="#AAAAAA"
                    size={23}
                    thickness={1.9}
                    className={classes.progress}
                  />
                </div>
              ) : (
                <div className={classes.formContainer}>
                  <div className={classes.dateContainer}>
                    <Grid item xs={6} sm={3} md={2.5}>
                      <h6 style={{ marginTop: "0px", marginBottom: "0px" }}>
                        BKI API:
                      </h6>
                    </Grid>
                    <Grid item xs={6} sm={9} md={9.5}>
                      <div
                        className={
                          bkiStatus ? classes.statusOK : classes.statusFailed
                        }
                      />
                    </Grid>
                  </div>
                  <div className={classes.dateContainer}>
                    <Grid item xs={6} sm={3} md={2.5}>
                      <h6 style={{ marginTop: "0px", marginBottom: "0px" }}>
                        TMN Balance API:
                      </h6>
                    </Grid>
                    <Grid item xs={6} sm={9} md={9.5}>
                      <div
                        className={
                          trueBalanceStatus
                            ? classes.statusOK
                            : classes.statusFailed
                        }
                      />
                    </Grid>
                  </div>
                  <div className={classes.dateContainer}>
                    <Grid item xs={6} sm={3} md={2.5}>
                      <h6 style={{ marginTop: "0px", marginBottom: "0px" }}>
                        TMN Payment API:
                      </h6>
                    </Grid>
                    <Grid item xs={6} sm={9} md={9.5}>
                      <div
                        className={
                          truePaymentStatus
                            ? classes.statusOK
                            : classes.statusFailed
                        }
                      />
                    </Grid>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
