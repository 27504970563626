import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
export const history = createBrowserHistory();
const store = createStore(
  createRootReducer(history), // root reducer with router state
  undefined,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history) // for dispatching history actions
    )
  )
);

export default store;
