import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import _ from "lodash";

export const defaultState = {
  plugSession: undefined
};

const customReducer = function(state = defaultState, action = {}) {
  switch (action.type) {
    case "CUSTOM":
      const data = _.get(action, "data", {});
      return {
        ...state,
        ...data
      };
    default:
      // need this for default case
      return state;
  }
};

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: customReducer
  });
