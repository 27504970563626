import React from "react";
import { Switch, Route } from "react-router";
import Home from "./../views/Home";
import Healthcheck from "./../views/Healthcheck";
import Maintenance from "./../views/Maintenance";
import ReportDaily from "./../views/ReportDaily";
import ReportMonthly from "./../views/ReportMonthly";
import Staff from "./../views/Staff";
import CreateStaff from "./../views/CreateStaff";
import NotFoundDedicated from "./../views/NotFound";
import { connect } from "react-redux";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../libs/api";
import Layout from "./../components/Layout/Layout";

class Auth extends React.Component {
  state = {
    isShowAlertSession: false
  };

  componentDidMount = () => {
    const { plugExpire } = this.props;
    const currentTime = parseInt(new Date().getTime() / 1000);
    if (plugExpire > currentTime) {
      this.timeout = setTimeout(() => {
        this.setState({
          isShowAlertSession: true
        });
        this.timeoutAutologout = setTimeout(() => {
          API.post("/signout");
          window.location.replace("/");
        }, (plugExpire - currentTime) * 1000);
      }, (plugExpire - currentTime - 30) * 1000);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.plugExpire !== this.props.plugExpire) {
      clearTimeout(this.timeout);
      clearTimeout(this.timeoutAutologout);
      const currentTime = parseInt(new Date().getTime() / 1000);
      const { plugExpire } = nextProps;
      if (plugExpire > currentTime) {
        this.setState({
          isShowAlertSession: false
        });
        this.timeout = setTimeout(() => {
          this.setState({
            isShowAlertSession: true
          });
          this.timeoutAutologout = setTimeout(() => {
            API.post("/signout");
            window.location.replace("/");
          }, (plugExpire - currentTime) * 1000);
        }, (plugExpire - currentTime - 30) * 1000);
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hideAlert = () => {
    this.setState({
      isShowAlertSession: false
    });
  };

  confirmAlert = () => {
    const { login } = this.props;
    this.setState({
      isShowAlertSession: false
    });
    API.get("/keep-alive", {}, false).then(response => {
      const { data } = response;
      if (data.success) {
        const displayName = _.get(data, "display_name", "");
        const lastSignin = _.get(data, "last_signin_time", "");
        const email = _.get(data, "email", "");
        const plugRole = _.get(data, "role", "");
        const plugSession = API.getSession();
        const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
        sessionStorage.setItem("plugSession", plugSession);
        sessionStorage.setItem("plugExpire", plugExpire);
        sessionStorage.setItem("plugRole", plugRole);
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        localStorage.removeItem("sessionStorage");
        login(plugSession, plugExpire, plugRole, displayName, lastSignin, email);
      }
    });
  };

  render() {
    const { isShowAlertSession } = this.state;
    return (
      <Layout>
        {isShowAlertSession && (
          <SweetAlert
            confirmBtnText="Stay Connected"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            title="Your Session is About to Expire!"
            onConfirm={this.confirmAlert}
            confirmBtnStyle={{ backgroundColor: "#063868" }}
          >
            Redirecting in a few seconds.
            <br />
          </SweetAlert>
        )}
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/healthcheck" component={Healthcheck} />
          <Route path="/maintenance" component={Maintenance} />
          <Route path="/report-daily" component={ReportDaily} />
          <Route path="/report-monthly" component={ReportMonthly} />
          <Route path="/staffs" component={Staff} />
          <Route path="/create-staff" component={CreateStaff} />
          <Route component={NotFoundDedicated} />
        </Switch>
      </Layout>
    );
  }
}

const mapStatetoProps = state => {
  return {
    plugSession: _.get(state, "auth.plugSession", ""),
    plugExpire: _.get(state, "auth.plugExpire", 0)
  };
};

const mapDispatchtoProps = dispatch => {
  return {
    login: (plugSession, plugExpire, plugRole, displayName, lastSignin, email) => {
      dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire, plugRole, displayName, lastSignin, email }
      });
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(Auth);
