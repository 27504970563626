import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import API from "../../libs/api";
import _ from "lodash";

import ReCAPTCHA from "react-google-recaptcha";
const styles = {};

class BlankPage extends Component {
  state = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    email: "",
    recaptchaRef: ""
  };
  constructor(props) {
    super(props);
    this.state.recaptchaRef = React.createRef();
  }
  submit = async () => {
    const recaptchaValue = this.state.recaptchaRef.current.getValue();
    const { isLoading, email } = this.state;
    if (!!email && isLoading === false) {
      this.setState({
        isSuccess: false,
        isError: false,
        isLoading: true
      });
      try {
        const response = await API.postWithoutCredentials("/forgot_password", {
          email,
          recaptcha_value: recaptchaValue
        });
        if (_.get(response, "data.success", false) === true) {
          this.setState({
            isError: false,
            isSuccess: true,
            isLoading: false
          });
        } else {
          this.state.recaptchaRef.current.reset();
          this.setState({
            isError: true,
            isSuccess: false,
            isLoading: false
          });
        }
      } catch (error) {
        this.state.recaptchaRef.current.reset();
        this.setState({
          isError: true,
          isSuccess: false,
          isLoading: false
        });
      }
    }
  };

  validateCapcha = value => {};
  render = () => {
    const { isSuccess, isError, isLoading, email } = this.state;
    return (
      <div>
        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <div className="bg-primary p-4 text-white text-center position-relative">
              <h4 className="font-20 mb-4">Forgot Password</h4>
              <Link to="/" className="logo logo-admin">
                <img src="assets/images/logo-sm.png" height="24" alt="logo" />
              </Link>
            </div>

            <div className="account-card-content">
              {isSuccess && (
                <div className="alert alert-success m-t-30" role="alert">
                  An email and instruction has been sent to you!
                </div>
              )}
              {isError && (
                <div className="alert alert-danger m-t-30" role="alert">
                  An error has occurred! please try again later.
                </div>
              )}

              <div className="form-group">
                <label for="useremail">Email</label>
                <input
                  onChange={event => {
                    this.setState({
                      email: event.target.value
                    });
                  }}
                  value={email}
                  disabled={isLoading}
                  type="email"
                  className="form-control"
                  id="useremail"
                  placeholder="Enter email"
                />
              </div>

              <div
                className="form-group row m-t-20 mb-0"
                style={{ justifyContent: "center" }}
              >
                <div style={{ marginBottom: "10px" }}>
                  <ReCAPTCHA
                    ref={this.state.recaptchaRef}
                    sitekey="6LfuXrsUAAAAADArWxPDqTyCcyi7RJ7Kj5FYv5jP"
                    onChange={this.validateCapcha}
                  />
                </div>
                <div className="col-12 text-right">
                  <button
                    onClick={this.submit}
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
