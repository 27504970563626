import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import API from "./../../libs/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

import ReCAPTCHA from "react-google-recaptcha";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formContainerLoading: {
    backgroundColor: "white",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  },
  formItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputError: {
    border: "1px solid red"
  },
  errorText: {
    color: "red",
    fontSize: "11px"
  }
};

function humanize(str) {
  let frags = str.split("_");
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

class BlankPage extends Component {
  state = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    isLoading: false,
    isPageLoading: true,
    roles: [],
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    isUpdating: false,
    recaptchaRef: ""
  };

  constructor(props) {
    super(props);
    this.state.recaptchaRef = React.createRef();
  }

  componentDidMount = async () => {
    const location = _.get(this.props, "location", { search: "" });
    const splittedData = location.search
      .slice(1, location.search.length)
      .split("&");
    const params = {};
    splittedData.forEach(data => {
      const splitMore = data.split("=");
      const key = splitMore[0];
      const value = splitMore[1];
      params[key] = value;
    });
    if (params.id) {
      this.setState({
        id: params.id,
        firstName: params.firstname,
        lastName: params.lastname,
        email: params.email,
        role: params.role_id,
        isUpdating: true
      });
    }
    try {
      const response = await API.get("/roles");
      if (response.status === 200) {
        const roles = _.get(response, "data", []);
        const firstRole = _.get(roles, 0, {});
        const firstRoleId = _.get(firstRole, "id", 0);
        this.setState({
          isPageLoading: false,
          roles
        });
        if (!!params.id === false) {
          this.setState({
            role: firstRoleId
          });
        }
      }
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if (responseStatus === 401) {
        const {
          history: { push }
        } = this.props;
        push("/login");
      }
    }
  };

  goBack = () => {
    const {
      history: { push }
    } = this.props;
    push("/staffs");
  };

  createStaff = async () => {
    const { id, isUpdating, firstName, lastName, email, role } = this.state;
    let foundValidateError = false;
    if (firstName.length < 1) {
      this.setState({
        firstNameError: true
      });
      foundValidateError = true;
    }
    if (lastName.length < 1) {
      this.setState({
        lastNameError: true
      });
      foundValidateError = true;
    }
    if (
      !email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState({
        emailError: true
      });
      foundValidateError = true;
    }
    if (foundValidateError) {
      return true;
    }

    const recaptchaValue = this.state.recaptchaRef.current.getValue();
    this.setState({
      isLoading: true
    });
    try {
      let response;
      if (isUpdating) {
        response = await API.put("/staffs/" + id, {
          email,
          firstname: firstName,
          lastname: lastName,
          role_id: parseInt(role),
          is_active: true,
          is_deleted: false,
          recaptcha_value: recaptchaValue
        });
      } else {
        response = await API.post("/staffs", {
          email,
          firstname: firstName,
          lastname: lastName,
          role_id: parseInt(role),
          recaptcha_value: recaptchaValue
        });
      }
      if (response.status === 200 || response.status === 201) {
        const {
          history: { push }
        } = this.props;
        push("/staffs");
      } else {
        this.setState({
          isLoading: false
        });
      }
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if (responseStatus === 401) {
        const {
          history: { push }
        } = this.props;
        push("/login");
      }
      if (responseStatus === 400) {
        this.setState({
          isLoading: false
        });
      }
    }
  };

  validateCapcha = value => {};
  render = () => {
    const { classes } = this.props;
    const {
      firstName,
      lastName,
      email,
      role,
      isLoading,
      isPageLoading,
      roles,
      firstNameError,
      lastNameError,
      emailError,
      isUpdating
    } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">
                  {isUpdating ? "Edit Staff" : "Create Staff"}
                </h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isPageLoading ? (
                <div className={classes.formContainerLoading}>
                  <CircularProgress
                    color="#AAAAAA"
                    size={23}
                    thickness={1.9}
                    className={classes.progress}
                  />
                </div>
              ) : (
                <div className={classes.formContainer}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={10} md={9}>
                      <div className={classes.formItem}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h6
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                            >
                              First name:
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <input
                              disabled={isLoading}
                              className={
                                "form-control" +
                                (firstNameError ? " " + classes.inputError : "")
                              }
                              type="text"
                              id="example-text-input"
                              value={firstName}
                              onChange={event => {
                                this.setState({
                                  firstName: event.target.value,
                                  firstNameError: false
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {firstNameError && (
                        <div className={classes.formItem}>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                            <Grid item xs={12} sm={9} md={9}>
                              <span className={classes.errorText}>
                                First name can not be blanked.
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <br />
                      <div className={classes.formItem}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h6
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                            >
                              Last name:
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <input
                              disabled={isLoading}
                              className={
                                "form-control" +
                                (lastNameError ? " " + classes.inputError : "")
                              }
                              type="text"
                              id="example-text-input"
                              value={lastName}
                              onChange={event => {
                                this.setState({
                                  lastName: event.target.value,
                                  lastNameError: false
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {lastNameError && (
                        <div className={classes.formItem}>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                            <Grid item xs={12} sm={9} md={9}>
                              <span className={classes.errorText}>
                                Last name can not be blanked.
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <br />
                      <div className={classes.formItem}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h6
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                            >
                              Email:
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <input
                              disabled={isLoading}
                              className={
                                "form-control" +
                                (emailError ? " " + classes.inputError : "")
                              }
                              type="email"
                              id="example-text-input"
                              value={email}
                              onChange={event => {
                                this.setState({
                                  email: event.target.value,
                                  emailError: false
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {emailError && (
                        <div className={classes.formItem}>
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              md={3}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                            <Grid item xs={12} sm={9} md={9}>
                              <span className={classes.errorText}>
                                Invalid email format.
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <br />
                      <div className={classes.formItem}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h6
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                            >
                              Role:
                            </h6>
                          </Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <select
                              disabled={isLoading}
                              value={role}
                              onChange={event => {
                                this.setState({
                                  role: event.target.value
                                });
                              }}
                              className="form-control"
                            >
                              {(() => {
                                return roles.map(x => {
                                  return (
                                    <option value={_.get(x, "id", 0)}>
                                      {humanize(_.get(x, "name", ""))}
                                    </option>
                                  );
                                });
                              })()}
                            </select>
                          </Grid>
                        </Grid>
                      </div>

                      <br />
                      <div className={classes.formItem}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ display: "flex", alignItems: "center" }}
                          ></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <ReCAPTCHA
                              ref={this.state.recaptchaRef}
                              sitekey="6LfuXrsUAAAAADArWxPDqTyCcyi7RJ7Kj5FYv5jP"
                              onChange={this.validateCapcha}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <br />
                      <div
                        className={classes.formItem}
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button onClick={this.goBack}>Back</Button>
                        <Button
                          disabled={isLoading}
                          onClick={this.createStaff}
                          color="primary"
                        >
                          {isUpdating ? "Save" : "Create"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
