import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import API from "./../../libs/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  },
  formContainerLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px"
  }
};

class BlankPage extends Component {
  state = {
    filterWord: "",
    data: [],
    isLoading: true,
    isDeleting: false,
    isOpenDialogDelete: false,
    deletingUser: undefined
  };

  goToCreate = () => {
    const {
      history: { push }
    } = this.props;
    push("/create-staff");
  };

  componentDidMount = async () => {
    try {
      const response = await API.get("/staffs?page=1&limit=9999");
      if (!!response.data && response.data.length > 0) {
        this.setState({
          data: response.data,
          isLoading: false
        });
      } else {
        this.setState({
          data: [],
          isLoading: false
        });
      }
    } catch (error) {
      this.setState({
        data: [],
        isLoading: false
      });
    }
  };

  deleteUser = id => async () => {
    this.setState({
      isDeleting: true
    });
    await API.delete("/staffs/" + id);
    this.setState({
      isLoading: true
    });
    const response = await API.get("/staffs?page=1&limit=9999");
    if (!!response.data && response.data.length > 0) {
      this.setState({
        data: response.data,
        isLoading: false,
        isDeleting: false,
        isOpenDialogDelete: false
      });
    } else {
      this.setState({
        data: [],
        isLoading: false,
        isDeleting: false,
        isOpenDialogDelete: false
      });
    }
  };

  openDeleteDialog = user => () => {
    this.setState({
      deletingUser: user,
      isOpenDialogDelete: true
    });
  };

  render = () => {
    const {
      classes,
      history: { push }
    } = this.props;
    let {
      filterWord,
      data,
      isLoading,
      isDeleting,
      isOpenDialogDelete,
      deletingUser
    } = this.state;
    const rows = data.filter(x => {
      return !!x.full_name.match(filterWord) || !!x.email.match(filterWord);
    });
    return (
      <div>
        {isOpenDialogDelete && (
          <SweetAlert
            showCancel
            confirmBtnText="Proceed"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="link"
            cancelBtnText="Cancel"
            title="Are you sure you want to remove staff?"
            onConfirm={this.deleteUser(deletingUser.id)}
            onCancel={() => {
              this.setState({
                isOpenDialogDelete: false
              });
            }}
          >
            Removing staff id {deletingUser.id} / name :{" "}
            {deletingUser.full_name}. The process could not be undone after
            confirmation.
          </SweetAlert>
        )}
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Staffs</h4>
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formContainer}>
                <div className={classes.columnContainer}>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={2} md={2}>
                        <Button
                          onClick={this.goToCreate}
                          color="primary"
                          style={{ width: "100%" }}
                        >
                          Create
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={10} md={10}>
                        <input
                          className="form-control"
                          type="text"
                          id="example-text-input"
                          placeholder="Search by email"
                          value={filterWord}
                          onChange={event => {
                            this.setState({
                              filterWord: event.target.value
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    {isLoading === true ? (
                      <div className={classes.formContainerLoading}>
                        <CircularProgress
                          color="#AAAAAA"
                          size={23}
                          thickness={1.9}
                          className={classes.progress}
                        />
                      </div>
                    ) : (
                      <div
                        className="card-body"
                        style={{
                          overflowX: "auto",
                          padding: "0px"
                        }}
                      >
                        {data.length > 0 ? (
                          <MDBTable
                            striped
                            bordered
                            hover
                            style={{ marginBottom: "0px" }}
                          >
                            <MDBTableHead>
                              <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                {/* <th>Role</th> */}
                                <th
                                  style={{
                                    width: "100px",
                                    textAlign: "center"
                                  }}
                                >
                                  Manage
                                </th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {(() => {
                                return rows.map(item => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.full_name}</td>
                                      <td>{item.email}</td>
                                      {/* <td>{item.role}</td> */}
                                      <td style={{ display: "flex" }}>
                                        <Button
                                          style={{
                                            marginRight: "9px",
                                            width: "100%"
                                          }}
                                          className="btn btn-light waves-effect"
                                          onClick={() => {
                                            push(
                                              "/create-staff?id=" +
                                                item.id +
                                                "&firstname=" +
                                                item.firstname +
                                                "&lastname=" +
                                                item.lastname +
                                                "&role_id=" +
                                                item.role_id +
                                                "&email=" +
                                                item.email
                                            );
                                          }}
                                        >
                                          <i className="mdi mdi-pencil" />
                                        </Button>
                                        <Button
                                          onClick={this.openDeleteDialog(item)}
                                          disabled={isDeleting}
                                          style={{ width: "100%" }}
                                          className="btn btn-light waves-effect"
                                        >
                                          <i className="mdi mdi-delete" />
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                });
                              })()}
                            </MDBTableBody>
                          </MDBTable>
                        ) : (
                          <div style={{ padding: "20px", textAlign: "center" }}>
                            <span>No data.</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
