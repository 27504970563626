import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import _ from "lodash";
import API from "./../../libs/api";
const styles = {};

class BlankPage extends Component {
  state = {
    isSuccess: false,
    isLoading: false,
    password: "",
    isError: false,
    errorType: "",
    passwordError: false
  };

  submit = async () => {
    const location = _.get(window, "location", {});
    if (_.get(location, "search.length", 0) === 0)
      return window.location.replace("/");
    const splittedData = _.get(location, "search", "")
      .slice(1, _.get(location, "search.length", 0))
      .split("&");
    const params = {};
    splittedData.forEach(data => {
      const splitMore = data.split("=");
      const key = splitMore[0];
      const value = splitMore[1];
      params[key] = value;
    });
    const { isLoading, password } = this.state;
    if (!!password && isLoading === false) {
      if (
        !password.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        )
      ) {
        this.setState({
          passwordError: true,
          isSuccess: false,
          isError: false,
          errorType: ""
        });
        return true;
      }
      this.setState({
        isSuccess: false,
        isError: false,
        isLoading: true,
        errorType: ""
      });
      try {
        const response = await API.postWithoutCredentials("/reset_password", {
          token: params.token,
          password,
          confirm_password: password
        });
        if (_.get(response, "data.success", false) === true) {
          window.location.replace("/");
        } else {
          this.setState({
            isError: true,
            isSuccess: false,
            isLoading: false,
            errorType: _.get(response, "data.errorMessage", "")
          });
        }
      } catch (error) {
        this.setState({
          isError: true,
          isSuccess: false,
          isLoading: false,
          errorType: ""
        });
      }
    }
  };

  render = () => {
    const {
      passwordError,
      errorType,
      isSuccess,
      isError,
      isLoading,
      password
    } = this.state;
    return (
      <div>
        <div className="wrapper-page">
          <div className="card overflow-hidden account-card mx-3">
            <div className="bg-primary p-4 text-white text-center position-relative">
              <h4 className="font-20 mb-4">Reset Password</h4>
              <Link to="/" className="logo logo-admin">
                <img src="assets/images/logo-sm.png" height="24" alt="logo" />
              </Link>
            </div>

            <div className="account-card-content">
              {isError && (
                <div className="alert alert-danger m-t-30" role="alert">
                  {errorType === "Expired"
                    ? "A token has been expired."
                    : "An error has occurred! please try again later."}
                </div>
              )}
              {passwordError && (
                <div className="alert alert-danger m-t-30" role="alert">
                  Password must have at least 8 characters with at least one
                  Capital letter, at least one lower case letter and at least
                  one number and one special character.
                </div>
              )}
              <div className="form-group">
                <label for="userpassword">New Password</label>
                <input
                  onChange={event => {
                    this.setState({
                      password: event.target.value,
                      passwordError: false
                    });
                  }}
                  error={true}
                  value={password}
                  disabled={isLoading}
                  type="password"
                  className="form-control"
                  id="userpassword"
                  placeholder="Enter new password"
                />
              </div>

              <div className="form-group row m-t-20 mb-0">
                <div className="col-12 text-right">
                  <button
                    onClick={this.submit}
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
