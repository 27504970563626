import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "reactstrap";

import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import FileDownload from "js-file-download";
import API from "../../libs/api";
import _ from "lodash";
import numeral from "numeral";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import SweetAlert from "react-bootstrap-sweetalert";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px",
  },
  formInline: {
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    marginTop: "20px",
  },
  statusSuccess: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "#28a745",
    display: "inline-block",
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "white",
  },
  statusPending: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "#ffc107",
    display: "inline-block",
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "white",
  },
  statusRefunded: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "#ffc107",
    display: "inline-block",
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "white",
  },
  statusFailed: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "#ec4561",
    display: "inline-block",
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "white",
  },
  formContainerLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
};

class BlankPage extends Component {
  state = {
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    filterText: "",
    filterOption: "resp_transaction_id",
    data: [],
    isLoading: undefined,
    showModal: false,
    modalMessage: "",
    isOpenDialogRefund: false,
    refundingID: null,
    product: "mCard",
    product_type: "all",
  };

  missingDate = () => {
    throw new Error("Missing date parameter!");
  };

  convertDateToString = (date = this.missingDate()) => {
    return date.toISOString().replace(/T/, " ").replace(/\..+/, "");
  };

  searchData = async () => {
    try {
      const {
        startDate,
        endDate,
        product_type,
        filterText,
        filterOption,
      } = this.state;
      this.setState({
        isLoading: true,
        data: [],
      });

      let path = "/transactions";
      path =
        `${path}?page=1&limit=99999` +
        `&from=${this.convertDateToString(startDate)}` +
        `&to=${this.convertDateToString(endDate)}` +
        `&product=${product_type}`;

      if (!!filterText) {
        path = path + "&colf=" + filterOption + ":" + filterText;
      }

      const response = await API.get(path);
      let {
        data: { data },
      } = response;
      data = data.map((x) => {
        const d = new Date(_.get(x, "transaction_date", ""));
        return {
          origin_id: x.id,
          id: x.tmn_transaction_id,
          transaction_status: x.transaction_status,
          isv_ref: x.isv_ref,
          e2e_ref: x.e2e_ref,
          tmn_id: x.tmn_id,
          transaction_id: x.transaction_id,
          transaction_type: x.transaction_type,
          transaction_date:
            [
              String(d.getMonth() + 1).padStart(2, 0),
              String(d.getDate()).padStart(2, 0),
              d.getFullYear(),
            ].join("/") +
            " " +
            [
              String(d.getHours()).padStart(2, 0),
              String(d.getMinutes()).padStart(2, 0),
              String(d.getSeconds()).padStart(2, 0),
            ].join(":"),
          transaction_amount: numeral(_.get(x, "transaction_amount", 0)).format(
            "0,0.00"
          ),
          tmn_transaction_status: x.tmn_transaction_status,
          tmn_payment_id: x.tmn_payment_id,
          tmn_payment_status: x.tmn_payment_status,
          can_refund: x.can_refund || false,
          failed_message: x.failed_reason,
          resp_transaction_id: x.tmn_transaction_id,
          mcard_number: x.mcard_number,
          mcard_topup_resp: x.mcard_topup_resp.replace(/['"]+/g, ""),
          mcard_topup_status: x.mcard_topup_status,
          verify_card_status: x.verify_card_status,
          verify_card_resp: x.verify_card_resp.replace(/['"]+/g, ""),
        };
      });
      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: error.message,
        isLoading: false,
        data: [],
      });
    }
  };

  exportData = async () => {
    try {
      const {
        startDate,
        endDate,
        product_type,
        filterText,
        filterOption,
      } = this.state;
      let path = "/transactions";
      path =
        `${path}?&proc=export` +
        `&from=${this.convertDateToString(startDate)}` +
        `&to=${this.convertDateToString(endDate)}` +
        `&product=${product_type}`;

      if (!!filterText) {
        path = path + "&colf=" + filterOption + ":" + filterText;
      }
      const response = await API.get(path);
      FileDownload(response.data, "report.csv");
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: error.message,
      });
    }
  };

  handleChangeEnd = (endDate) => {
    this.setState({
      endDate: new Date(endDate.setHours(23, 59, 59, 999)),
    });
  };

  refundFunction = async (tid) => {
    this.setState({
      isOpenDialogRefund: true,
      refundingID: tid,
      product: "mcard",
    });
  };

  refundX = async (tid, product) => {
    let path;
    path = `/refund/mcard/${tid}`;
    try {
      await API.post(path);
    } catch (error) {
      this.setState({
        isError: true,
        errorMessage: error.message,
        isOpenDialogRefund: false,
        refundingID: null,
      });
      return;
    }
    this.searchData();
    this.setState({
      isOpenDialogRefund: false,
      refundingID: null,
      product: null,
    });
  };

  handleChangeStart = (startDate) => {
    this.setState({
      startDate: new Date(startDate.setHours(0, 0, 0, 0)),
    });
  };

  filterDropDown = (product, filterOption) => {
    return (
      <select
        value={filterOption}
        onChange={(event) => {
          this.setState({
            filterOption: event.target.value,
          });
        }}
        className="form-control"
      >
        <option value="resp_transaction_id">TMN Transaction ID</option>
        <option value="e2e_ref">E2E Ref</option>
        <option value="transaction_amount">Transaction Amount</option>
        <option value="mcard_number">MCard Number</option>
        <option value="tmn_transaction_status">TMN Transaction Status</option>
        <option value="tmn_id">TMN ID</option>
      </select>
    );
  };

  showModal = (message) => {
    this.setState({
      showModal: true,
      modalMessage: message,
    });
  };

  drawColumn = (role) => {
    return (
      <>
        <tr>
          <th style={{ textAlign: "center", minWidth: "260px" }} rowSpan="2">
            {" "}
            TMN Transaction ID{" "}
          </th>
          <th style={{ textAlign: "center" }} colSpan="8">
            {" "}
            TMN Transaction Status{" "}
          </th>
          <th style={{ textAlign: "center" }} colSpan="15">
            {" "}
            MCard Info{" "}
          </th>
        </tr>
        <tr>
          <th style={{ textAlign: "center", minWidth: "200px" }}>
            {" "}
            Transaction Status{" "}
          </th>
          <th style={{ textAlign: "center", minWidth: "150px" }}>
            {" "}
            Transaction Type{" "}
          </th>
          <th style={{ textAlign: "center", minWidth: "200px" }}>
            {" "}
            Transaction Date & Time{" "}
          </th>
          <th style={{ textAlign: "center", minWidth: "330px" }}> ISV Ref </th>
          <th style={{ textAlign: "center", minWidth: "330px" }}> E2E Ref </th>
          <th style={{ textAlign: "center", minWidth: "200px" }}> TMN ID </th>
          <th style={{ textAlign: "center", minWidth: "190px" }}>
            {" "}
            Transaction Amount{" "}
          </th>
          <th style={{ textAlign: "center", minWidth: "200px" }}>
            {" "}
            TMN Transaction Status{" "}
          </th>
          <th style={{ textAlign: "center", minWidth: "125px" }}>
            Failed Reason
          </th>
          <th style={{ textAlign: "center", minWidth: "330px" }}>
            MCard Number
          </th>
          <th style={{ textAlign: "center", minWidth: "200px" }}>
            Verify Card Status
          </th>
          <th style={{ textAlign: "center", minWidth: "125px" }}>
            Failed Reason
          </th>
          <th style={{ textAlign: "center", minWidth: "200px" }}>
            MCard Top Up Status
          </th>
          <th style={{ textAlign: "center", minWidth: "125px" }}>
            Failed Reason
          </th>
          <th style={{ textAlign: "center", minWidth: "330px" }}>
            Major Payment Transaction No.
          </th>
          {role === "admin" && (
            <th style={{ textAlign: "center", minWidth: "150px" }}>
              {" "}
              Refund User{" "}
            </th>
          )}
        </tr>
      </>
    );
  };

  transactionStatus = (value) => {
    if (
      this.mcardTopupStatus(value) === "fail" &&
      this.tmnTransactionStatus(value) === "success"
    ) {
      return "topup failed";
    } else if (this.tmnTransactionStatus(value) === "refunded") {
      return "refunded";
    } else if (this.verifyCardStatus(value) === "fail") {
      return "verify fail";
    } else if (this.tmnTransactionStatus(value) === "pending") {
      return "waiting payment";
    } else if (this.tmnTransactionStatus(value) === "fail") {
      return "fail";
    } else if (this.mcardTopupStatus(value) === "pending") {
      return "waiting topup";
    } else {
      return "success";
    }
  };

  transactionStatusColumnClassName = (transaction, classes) => {
    switch (this.transactionStatus(transaction)) {
      case "success":
        return classes.statusSuccess;
      case "verify fail":
        return classes.statusFailed;
      case "waiting payment":
        return classes.statusPending;
      case "fail":
        return classes.statusFailed;
      case "topup failed":
        return classes.statusFailed;
      case "refunded":
        return classes.statusRefunded;
      case "pending notification":
        return classes.statusPending;
      case "waiting topup":
        return classes.statusPending;
      default:
        return "";
    }
  };

  isMcardTopupFailed = (transaction) => {
    return this.mcardTopupStatus(transaction) === "fail" && this.transactionStatus(transaction) != 'refunded';
  };

  mcardTopupStatus = (transaction) => {
    if (["fail", "pending"].includes(this.tmnTransactionStatus(transaction))) {
      return "";
    } else {
      return transaction.mcard_topup_status === "1" ? "success" : "fail";
    }
  };

  mcardTopupStatusColumnClassName = (transaction, classes) => {
    switch (this.mcardTopupStatus(transaction)) {
      case "success":
        return classes.statusSuccess;
      case "fail":
        return classes.statusFailed;
      case "pending":
        return classes.statusPending;
      default:
        return "";
    }
  };

  verifyCardStatus = (value) => {
    return value.verify_card_status === "1" ? "success" : "fail";
  };

  verifyCardStatusColumnClassName = (transaction, classes) => {
    switch (this.verifyCardStatus(transaction)) {
      case "success":
        return classes.statusSuccess;
      case "fail":
        return classes.statusFailed;
      default:
        return classes.statusPending;
    }
  };

  isVerifyCardFailed = (transaction) => {
    return (
      !!transaction.verify_card_resp &&
      !["success", "success_idempotent", "refunded", "pending"].includes(
        this.verifyCardStatus(transaction).toLowerCase().trim()
      )
    );
  };

  tmnTransactionStatus = (transaction) => {
    switch (transaction.tmn_transaction_status.toLowerCase()) {
      case "success":
        return "success";
      case "refunded":
        return "refunded";
      case "refunded by system":
        return "refunded";
      case "fail":
        return "fail";
      default:
        return "pending";
    }
  };

  tmnTransactionStatusColumnClassName = (transaction, classes) => {
    switch (this.tmnTransactionStatus(transaction)) {
      case "success":
        return classes.statusSuccess;
      case "refunded":
        return classes.statusRefunded;
      case "fail":
        return classes.statusFailed;
      default:
        return classes.statusPending;
    }
  };

  isTmnTransactionFailed = (transaction) => {
    return this.tmnTransactionStatus(transaction) === "fail";
  };

  insuranceStatus = (value) => {
    return _.get(value, "insurance_status", "").toLowerCase();
  };

  insuranceStatusColumnClassName = (transaction, classes) => {
    switch (this.insuranceStatus(transaction)) {
      case "success":
        return classes.statusSuccess;
      case "pending":
        return classes.statusPending;
      case "fail":
        return classes.statusFailed;
      default:
        return "";
    }
  };

  mCardTransactionBody = (transaction, classes, role) => {
    return (
      <tr key={transaction.transaction_id}>
        <td style={{ textAlign: "center" }}>
          {transaction.resp_transaction_id}
        </td>
        {/* TMN Transaction ID */}
        <td style={{ textAlign: "center" }}>
          <div
            className={this.transactionStatusColumnClassName(
              transaction,
              classes
            )}
          >
            {this.transactionStatus(transaction).toUpperCase()}
          </div>
        </td>
        {/* Transaction Status */}
        <td style={{ textAlign: "center" }}>{transaction.transaction_type}</td>
        {/* Transaction Type */}
        <td style={{ textAlign: "center" }}>{transaction.transaction_date}</td>
        {/* Transaction Date/Time */}
        <td style={{ textAlign: "center" }}>{transaction.isv_ref}</td>{" "}
        {/* ISV Ref */}
        <td style={{ textAlign: "center" }}>{transaction.e2e_ref}</td>{" "}
        {/* E2E Ref */}
        <td style={{ textAlign: "center" }}>{transaction.tmn_id}</td>
        {/* TMN ID */}
        <td style={{ textAlign: "center" }}>
          {transaction.transaction_amount.replace(/\.00$/, "")}
        </td>
        {/* Transaction Amount */}
        <td style={{ textAlign: "center" }}>
          <div
            className={this.tmnTransactionStatusColumnClassName(
              transaction,
              classes
            )}
          >
            {!transaction.tmn_transaction_status ||
            transaction.tmn_transaction_status.length === 0
              ? "PENDING"
              : transaction.tmn_transaction_status.toUpperCase() === "REFUNDED"
              ? "REFUNDED BY USER"
              : transaction.tmn_transaction_status.toUpperCase()}
          </div>
        </td>
        {/* TMN Transaction Status */}
        <td style={{ textAlign: "center" }}>
          {this.isTmnTransactionFailed(transaction) && (
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => {
                this.showModal(transaction.failed_message);
              }}
            >
              {" "}
              REASON{" "}
            </div>
          )}
        </td>
        {/* Failed Reason */}
        <td style={{ textAlign: "center" }}>{transaction.mcard_number}</td>
        {/* Mcard Number */}
        <td style={{ textAlign: "center" }}>
          <div
            className={this.verifyCardStatusColumnClassName(
              transaction,
              classes
            )}
          >
            {this.verifyCardStatus(transaction).toUpperCase()}
          </div>
        </td>
        {/* Verify Card Resp */}
        <td style={{ textAlign: "center" }}>
          {this.isVerifyCardFailed(transaction) && (
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => {
                this.showModal(transaction.verify_card_resp);
              }}
            >
              {" "}
              REASON{" "}
            </div>
          )}
        </td>
        {/* Verify Card Failed Reason */}
        <td style={{ textAlign: "center" }}>
          <div
            className={this.mcardTopupStatusColumnClassName(
              transaction,
              classes
            )}
          >
            {this.mcardTopupStatus(transaction).toUpperCase()}
          </div>
        </td>
        {/* MCard Top Up Status */}
        <td style={{ textAlign: "center" }}>
          {this.isMcardTopupFailed(transaction) && (
            <div
              style={{ textDecoration: "underline" }}
              onClick={() => {
                this.showModal(transaction.mcard_topup_resp);
              }}
            >
              {" "}
              REASON{" "}
            </div>
          )}
        </td>
        {/* MCard Top Up Failed Reason */}
        <td style={{ textAlign: "center" }}>
          {transaction.mcard_order_id}
        </td>{" "}
        {/* Major Payment Transaction No. */}
        {role === "admin" && (
          <td style={{ textAlign: "center" }}>
            <Button
              disabled={
                !(
                  transaction.can_refund &&
                  this.tmnTransactionStatus(transaction) != "pending"
                )
              }
              onClick={() => {
                if (
                  transaction.can_refund &&
                  this.tmnTransactionStatus(transaction) != "pending"
                ) {
                  this.refundFunction(transaction.origin_id);
                }
              }}
              style={!transaction.can_refund ? { opacity: 0.3 } : {}}
            >
              Refund
            </Button>
          </td>
        )}
        {/* Refund User*/}
      </tr>
    );
  };

  drawTable = (data) => {
    const { classes, role } = this.props;
    let table = [];
    let i = 0;

    for (i = 0; i < data.length; i++) {
      let x = data[i];
      table.push(this.mCardTransactionBody(x, classes, role));
    }

    return table;
  };

  render = () => {
    const { classes, role } = this.props;
    let {
      startDate,
      endDate,
      filterOption,
      filterText,
      product,
      data,
      isLoading,
      showModal,
      modalMessage,
      isOpenDialogRefund,
      refundingID,
      isError,
      errorMessage,
    } = this.state;
    return (
      <div>
        {isError && (
          <SweetAlert
            confirmBtnText="Close"
            confirmBtnBsStyle="link"
            title="Error"
            onConfirm={() => {
              this.setState({
                isError: false,
              });
            }}
          >
            {errorMessage}
          </SweetAlert>
        )}
        {isOpenDialogRefund && (
          <SweetAlert
            showCancel
            confirmBtnText="Proceed"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="link"
            cancelBtnText="Cancel"
            title="Are you sure you want to refund?"
            onConfirm={() => {
              this.refundX(refundingID, product);
            }}
            onCancel={() => {
              this.setState({
                isOpenDialogRefund: false,
              });
            }}
          >
            Refunding transaction id {refundingID}. The process could not be
            undone after confirmation.
          </SweetAlert>
        )}
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Transactions</h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formContainer}>
                <div className={classes.dateContainer}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3}>
                      <h6 style={{ marginTop: "0px" }}>From:</h6>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        onChange={this.handleChangeStart}
                        id="date_start"
                      />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <h6 style={{ marginTop: "0px" }}>To:</h6>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        onChange={this.handleChangeEnd}
                        minDate={startDate}
                        maxDate={new Date()}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.columnContainer}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3}>
                      <h6>Product:</h6>
                      <select
                        className="form-control"
                        onChange={(event, value) => {
                          this.setState({ product: event.target.value });
                        }}
                      >
                        <option value="mCard">MCard</option>
                      </select>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.columnContainer}>
                  <h6>Column:</h6>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3}>
                        {this.filterDropDown(product, filterOption)}
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <input
                          className="form-control"
                          type="text"
                          id="example-text-input"
                          value={filterText}
                          onChange={(event) => {
                            this.setState({
                              filterText: event.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={this.searchData}
                    style={{ marginRight: "9px" }}
                    color="primary"
                  >
                    Search
                  </Button>
                  <Button
                    style={{ marginRight: "9px" }}
                    className="btn btn-light waves-effect"
                    onClick={() => {
                      this.setState({
                        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
                        endDate: new Date(new Date().setHours(23, 59, 59, 999)),
                        filterOption: "",
                        filterText: "",
                        product: "",
                      });
                    }}
                  >
                    Clear
                  </Button>
                  <Button color="link" onClick={this.exportData}>
                    Export
                  </Button>

                </div>
              </div>
            </Grid>
            {(isLoading === true || isLoading === false) && (
              <Grid item xs={12}>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {isLoading === true ? (
                        <div className={classes.formContainerLoading}>
                          <CircularProgress
                            color="#AAAAAA"
                            size={23}
                            thickness={1.9}
                            className={classes.progress}
                          />
                        </div>
                      ) : (
                        <div
                          className="card-body"
                          style={{ overflowX: "auto", padding: "0px" }}
                        >
                          {data.length > 0 ? (
                            <MDBTable
                              striped
                              bordered
                              hover
                              style={{ marginBottom: "0px" }}
                            >
                              <MDBTableHead>
                                {" "}
                                {this.drawColumn(role)}{" "}
                              </MDBTableHead>
                              <MDBTableBody>
                                {" "}
                                {this.drawTable(data)}{" "}
                              </MDBTableBody>
                            </MDBTable>
                          ) : (
                            <div
                              style={{ padding: "20px", textAlign: "center" }}
                            >
                              <span>No data.</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <Modal
          styles={{ outline: 0 }}
          open={showModal}
          onClose={() => {
            this.setState({ showModal: false });
          }}
        >
          <div
            style={{
              outline: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              maxHeight: "80%",
              backgroundColor: "white",
              borderRadius: "8px",
              textAlign: "center",
              padding: "18px",
            }}
          >
            {modalMessage}
          </div>
        </Modal>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.plugRole,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
