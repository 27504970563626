import axios from "axios";
import _ from "lodash";
import store from "./../configureStore";

class API {
  static endpoint = process.env.REACT_APP_AWS_API_ENDPOINT;
  static version = "v1";
  static async getWithoutCredentials(path, options = {}, redirect = true) {
    path = `${API.endpoint}/${API.version}${path}`;
    try {
      const response = await axios.get(path, options);
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
    }
  }

  static async postWithoutCredentials(
    path,
    options = {},
    customOptions = {},
    redirect = true
  ) {
    path = `${API.endpoint}/${API.version}${path}`;
    try {
      const response = await axios.post(path, options, customOptions);
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
    }
  }

  static async get(path, customOptions = {}, redirect = true) {
    path = `${API.endpoint}/${API.version}${path}`;
    const configuredHeader = _.get(customOptions, "headers", {});
    const session = this.getSession();
    if (!!session) {
      configuredHeader["X-Plug-Authorization"] = session;
    }
    const convertedOptions = { ...customOptions, headers: configuredHeader };
    try {
      const response = await axios.get(path, convertedOptions);
      const plugSession = session;
      const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
      sessionStorage.setItem("plugSession", plugSession);
      sessionStorage.setItem("plugExpire", plugExpire);
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      store.dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire }
      });
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
      throw error;
    }
  }

  static async delete(path, customOptions = {}, redirect = true) {
    path = `${API.endpoint}/${API.version}${path}`;
    const configuredHeader = _.get(customOptions, "headers", {});
    const session = this.getSession();
    if (!!session) {
      configuredHeader["X-Plug-Authorization"] = session;
    }
    const convertedOptions = { ...customOptions, headers: configuredHeader };
    try {
      const response = await axios.delete(path, convertedOptions);
      const plugSession = session;
      const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
      sessionStorage.setItem("plugSession", plugSession);
      sessionStorage.setItem("plugExpire", plugExpire);
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      store.dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire }
      });
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
      throw error;
    }
  }

  static async post(path, options = {}, customOptions = {}, redirect = true) {
    path = `${API.endpoint}/${API.version}${path}`;
    const configuredHeader = _.get(customOptions, "headers", {});
    const session = this.getSession();
    if (!!session) {
      configuredHeader["X-Plug-Authorization"] = session;
    }
    const convertedOptions = { ...customOptions, headers: configuredHeader };
    try {
      const response = await axios.post(path, options, convertedOptions);
      const plugSession = session;
      const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
      sessionStorage.setItem("plugSession", plugSession);
      sessionStorage.setItem("plugExpire", plugExpire);
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      store.dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire }
      });
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
      throw error;
    }
  }

  static async put(path, options = {}, customOptions = {}, redirect = true) {
    path = `${API.endpoint}/${API.version}${path}`;
    const configuredHeader = _.get(customOptions, "headers", {});
    const session = this.getSession();
    if (!!session) {
      configuredHeader["X-Plug-Authorization"] = session;
    }
    const convertedOptions = { ...customOptions, headers: configuredHeader };
    try {
      const response = await axios.put(path, options, convertedOptions);
      const plugSession = session;
      const plugExpire = parseInt(new Date().getTime() / 1000) + 900;
      sessionStorage.setItem("plugSession", plugSession);
      sessionStorage.setItem("plugExpire", plugExpire);
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
      localStorage.removeItem("sessionStorage");
      store.dispatch({
        type: "CUSTOM",
        data: { plugSession, plugExpire }
      });
      return response;
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if ((responseStatus === 401 || responseStatus === 403) && redirect) {
        window.location.replace("/login");
      }
      throw error;
    }
  }

  static getSession() {
    let plugSession = _.get(store.getState(), "plugSession", false);
    if (!!plugSession === false) {
      return sessionStorage.getItem("plugSession");
    }
    return plugSession;
  }

  static getSessionExpiration() {
    let plugExpiration = _.get(store.getState(), "plugExpiration", false);
    if (!!plugExpiration === false) {
      return sessionStorage.getItem("plugExpiration");
    }
    return plugExpiration;
  }
}

export default API;
