import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import API from "../../libs/api";
const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  }
};

class BlankPage extends Component {
  state = {
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    option: "all",
    settlement_daily: false,
    settlement_mdr: false
  };

  handleChangeEnd = endDate => {
    this.setState({
      endDate: new Date(endDate.setHours(23, 59, 59, 999))
    });
  };

  handleChangeStart = startDate => {
    this.setState({
      startDate: new Date(startDate.setHours(0, 0, 0, 0))
    });
  };

  exportData = async () => {
    const {
      startDate,
      endDate,
      option,
      settlement_daily,
      settlement_mdr
    } = this.state;

    let type = [];
    if (!!settlement_mdr) {
      type.push("settlement_mdr");
    }
    if (!!settlement_daily) {
      type.push("settlement_daily");
    }
    API.get(
      "/reports/daily?from=" +
        startDate
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "") +
        "&to=" +
        endDate
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "") +
        "&product=" +
        option +
        "&type=" +
        type.join(","),
      {}
    ).then(response => {
      let a = document.createElement("a");
      const url = response.data; // "https://database-s3dataexport-7usxcjuptmck.s3-ap-southeast-1.amazonaws.com/tmp/monthly_report.zip";
      a.href = url;
      const fileName = url.split("/").pop();
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      //FileDownload(
      //  response.data,
      //  "report-" + moment().format("YYYY-MM-DD") + ".csv"
      //);
    });
  };

  render = () => {
    const { classes } = this.props;
    const {
      startDate,
      endDate,
      option,
      settlement_daily,
      settlement_mdr
    } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Report</h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.formContainer}>
                <div className={classes.dateContainer}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={4} md={3}>
                      <h6 style={{ marginTop: "0px" }}>From:</h6>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        onChange={this.handleChangeStart}
                        id="date_start"
                      />
                    </Grid>
                    {"   "}
                    <Grid item xs={6} sm={4} md={3}>
                      <h6 style={{ marginTop: "0px" }}>To:</h6>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        onChange={this.handleChangeEnd}
                        minDate={startDate}
                        maxDate={new Date()}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.columnContainer}>
                  <h6>Product:</h6>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4} md={3}>
                        <select
                          value={option}
                          onChange={event => {
                            this.setState({
                              option: event.target.value
                            });
                          }}
                          className="form-control"
                        >
                          <option value="all">All Products</option>
                          <option value="cmi">CMI</option>
                          <option value="vmi">VMI</option>
                          {/* <option value="ta">TA</option> */}
                        </select>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.columnContainer}>
                  <h6>Report:</h6>
                  <div className={classes.formInline}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={8} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={settlement_daily}
                              onChange={event => {
                                this.setState({
                                  settlement_daily: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Settlement Daily"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={settlement_mdr}
                              onChange={event => {
                                this.setState({
                                  settlement_mdr: event.target.checked
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Settlement MDR"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={() => {
                      this.setState({
                        startDate: new Date(),
                        endDate: new Date(),
                        option: "all"
                      });
                    }}
                    style={{ marginRight: "9px" }}
                    className="btn btn-light waves-effect"
                  >
                    Clear
                  </Button>
                  <Button onClick={this.exportData} color="link">
                    Export
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
