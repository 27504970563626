import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import API from "../../libs/api";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  formContainer: {
    backgroundColor: "white",
    padding: "12px"
  },
  formContainerLoading: {
    backgroundColor: "white",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  formInline: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    marginTop: "20px"
  },
  dateContainer: {
    display: "flex"
  }
};

class BlankPage extends Component {
  state = {
    isLoading: true,
    mcard: true,
    mcardId: 0,
  };

  componentDidMount = async () => {
    try {
      const mcard_response = await API.get("/mcard/enable_product");

      if (mcard_response.status === 200) {
        const mcard_data = _.get(mcard_response, "data", {});
        let mcard = _.find(mcard_data, function(o) {
          return o.name === "mcard";
        });

        this.setState({
          isLoading: false,
          mcard: mcard.enable,
          mcardId: mcard.id
        });
      }
    } catch (error) {
      const responseStatus = _.get(error, "response.status", 0);
      if (responseStatus === 401) {
        const {
          history: { push }
        } = this.props;
        push("/login");
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    const {mcard} = prevState;
    if (mcard !== this.state.mcard) {
      const response = await API.put(`/mcard/enable_product/${this.state.mcardId}`, {
        enable: this.state.mcard
      });
    }
  };

  render = () => {
    const { classes } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Maintenance</h4>
              </div>
            </div>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading ? (
                <div className={classes.formContainerLoading}>
                  <CircularProgress
                    color="#AAAAAA"
                    size={23}
                    thickness={1.9}
                    className={classes.progress}
                  />
                </div>
              ) : (
                <div className={classes.formContainer}>
                  <div className={classes.dateContainer}>
                    <h6
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        marginRight: "16px"
                      }}
                    >
                      mcard:
                    </h6>
                    <input
                      type="checkbox"
                      id="mcard"
                      checked={this.state.mcard ? "checked" : ""}
                      switch="none"
                      onChange={() => this.setState({ mcard: !this.state.mcard })}
                    />
                    <label for="mcard" data-on-label="On" data-off-label="Off" />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
