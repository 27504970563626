import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const styles = {};

class BlankPage extends Component {
  render = () => {
    return (
      <div>
        <span>Not found</span>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlankPage));
