import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";
import "./Leftsidebar.css";

class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: "index",
      SubTab: "",
      MoreTab: "",
      email_menu: false,
      ui_menu: false,
      form_menu: false,
      chart_menu: false,
      table_menu: false,
      icon_menu: false,
      map_menu: false,
      extra_menu: false,
      pages_menu: false,
      et_menu: false
    };
  }
  setActiveTab = (tab, subtab, moretab, e) => {
    this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
  };

  componentDidMount() {
    const {
      location: { pathname }
    } = this.props;

    if (pathname === "/report-daily" || pathname === "/report-monthly") {
      this.setState({
        email_menu: true
      });
    }
    if ($(window).width() < 1025) {
      $("body").addClass("enlarged");
    } else {
      $("body").removeClass("enlarged");
    }

    $(".button-menu-mobile").on("click", function(event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
      if ($("body").hasClass("enlarged")) {
        $("#email_menu_hide").hide();
        $("#ui_menu_hide").hide();
        $("#form_menu_hide").hide();
        $("#chart_menu_hide").hide();
        $("#table_menu_hide").hide();
        $("#icon_menu_hide").hide();
        $("#map_menu_hide").hide();
        $("#page_menu_hide").hide();
        $("#extra_menu_hide").hide();
        $("#et_menu_hide").hide();
      } else {
        $("#email_menu_hide").show();
        $("#ui_menu_hide").show();
        $("#form_menu_hide").show();
        $("#chart_menu_hide").show();
        $("#table_menu_hide").show();
        $("#icon_menu_hide").show();
        $("#map_menu_hide").show();
        $("#page_menu_hide").show();
        $("#extra_menu_hide").show();
        $("#et_menu_hide").show();
      }
    });
  }

  render() {
    const {
      location: { pathname },
      role,
      email = ""
    } = this.props;

    return (
      <AUX>
        <div className="left side-menu">
          <Scrollbars style={{ height: 980 }}>
            <div>
              <div id="sidebar-menu">
                <ul className="metismenu" id="side-menu">
                  <li className="menu-title">Main</li>
                  { !(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                    <li>
                      <Link
                        to="home"
                        className={
                          pathname === "/home"
                            ? "waves-effect active"
                            : "waves-effect"
                        }
                        style={pathname === "/home" ? { color: "white" } : {}}
                      >
                        <i className="ti-files" />
                        <span> Transactions </span>
                      </Link>
                    </li>
                  )}
                  {false && role !== "clt" && (
                    <li
                      className={
                        pathname === "/report-daily" ||
                        pathname === "/report-monthly"
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({ email_menu: !this.state.email_menu })
                        }
                        className={
                          this.state.Tab === "email"
                            ? "waves-effect active"
                            : "waves-effect"
                        }
                      >
                        <i className="ti-export" />
                        <span>
                          {" "}
                          Report{" "}
                          <span className="float-right menu-arrow">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      {this.state.email_menu ? (
                        <ul className="submenu" id="email_menu_hide">
                          {!(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                            <li
                              className={
                                pathname === "/report-daily" ? "active" : ""
                              }
                            >
                              <Link
                                to="report-daily"
                                style={
                                  pathname === "/report-daily"
                                    ? { color: "white" }
                                    : {}
                                }
                              >
                                Daily
                              </Link>
                            </li>
                          )}
                          <li
                            className={
                              pathname === "/report-monthly" ? "active" : ""
                            }
                          >
                            <Link
                              to="report-monthly"
                              style={
                                pathname === "/report-monthly"
                                  ? { color: "white" }
                                  : {}
                              }
                            >
                              Monthly
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </li>
                  )}

                  {(role === "admin" || role === "so") &&
                    !(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                      <li className="menu-title">Setting</li>
                    )}
                  {(role === "admin" || role === "so") &&
                    !(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                      <li>
                        <Link
                          to="maintenance"
                          className={
                            pathname === "/maintenance"
                              ? "waves-effect active"
                              : "waves-effect"
                          }
                          style={
                            pathname === "/maintenance"
                              ? { color: "white" }
                              : {}
                          }
                        >
                          <i className="ti-alert" />
                          <span> Maintenance </span>
                        </Link>
                      </li>
                    )}
                  {false && (role === "admin" || role === "so") &&
                    !(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                      <li>
                        <Link
                          to="healthcheck"
                          className={
                            pathname === "/healthcheck"
                              ? "waves-effect active"
                              : "waves-effect"
                          }
                          style={
                            pathname === "/healthcheck"
                              ? { color: "white" }
                              : {}
                          }
                        >
                          <i className="ti-heart" />
                          <span> Health-check </span>
                        </Link>
                      </li>
                    )}
                  {(role === "admin") &&
                    !(["tmn@covid.com", "tmn@covidasia.com", "tmn@cancer.com"]).includes(email) && (
                      <li>
                        <Link
                          to="staffs"
                          className={
                            pathname === "/staffs" ||
                            pathname === "/create-staff"
                              ? "waves-effect active"
                              : "waves-effect"
                          }
                          style={
                            pathname === "/staffs" ||
                            pathname === "/create-staff"
                              ? { color: "white" }
                              : {}
                          }
                        >
                          <i className="ti-user" />
                          <span> Staffs </span>
                        </Link>
                      </li>
                    )}

                  <li>
                    <Link to="#">&nbsp;</Link>
                  </li>
                  <li>
                    <Link to="#">&nbsp;</Link>
                  </li>
                  <li>
                    <Link to="#">&nbsp;</Link>
                  </li>
                </ul>
              </div>
              <div className="clearfix" />
            </div>
          </Scrollbars>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.plugRole,
    email: state.auth.email
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(sidebar));
